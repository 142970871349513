<template>
  <div class="transfer-detail-modal">
    <b-modal v-model="visible" v-if="visible" v-on="$listeners" size="lg" hide-header-close
             header-class="justify-content-center">

      <template #modal-title>
        <h1>{{ $t('transfers.details') }}</h1>
      </template>

      <ul class="list-group">
        <li v-if="record.external_order_number" class="list-group-item">
          <b-row>
            <b-col>
              <h5>{{ $t("common.order_number") }}</h5>
              {{ record.external_order_number }}
            </b-col>
          </b-row>
        </li>
        <li v-if="record.external_invoice_number" class="list-group-item">
          <b-row>
            <b-col>
              <h5>{{ $t("common.invoice_number") }}</h5>
              {{ record.external_invoice_number }}
            </b-col>
          </b-row>
        </li>
        <li class="list-group-item">
          <b-row>
            <b-col>
              <h5>{{ $t("common.start_city") }}</h5>
              <h4>
                {{ record.start_shop.contact.name || record.start_shop.role_s }}<br>
                <small>
                  {{ record.start_shop.street_name }}<br>
                  {{ record.start_shop.postcode }}, {{ record.start_shop.city.name }}
                  <div v-if="record.start_shop.contact.phone_number"><font-awesome-icon icon="phone"/> {{ record.start_shop.contact.phone_number }}</div>
                </small>
              </h4>
              <small>
                <a class="" :href="record.route_link" target="_blank">
                  <font-awesome-icon icon="map-marker-alt"/>
                  {{ $t("transfers.on_a_map") }}
                </a>
              </small>
            </b-col>
            <b-col>
              <h5>{{ $t("common.end_city") }}</h5>
              <h4>
                {{ record.end_shop.contact.name || record.end_shop.role_s }}<br>
                <small>
                  {{ record.end_shop.street_name }}<br>
                  {{ record.end_shop.postcode }}, {{ record.end_shop.city.name }}
                  <div v-if="record.end_shop.contact.phone_number"><font-awesome-icon icon="phone"/> {{ record.end_shop.contact.phone_number }}</div>
                </small>
              </h4>
            </b-col>
          </b-row>
        </li>
        <li class="list-group-item">
          <b-row>
            <b-col>
              <h5>{{ $t("multiples.vehicle.one") }}</h5>
              {{ record.vehicle.name }}
            </b-col>
            <b-col>
              <h5>{{ $t("common.license_plate") }}</h5>
              {{ record.license_plate }}
            </b-col>
          </b-row>
        </li>
        <li class="list-group-item">
          <b-row>
            <b-col>
              <h5>{{ $t("common.pickup_since") }}</h5>
              {{ record.start_date | formatDate }}
            </b-col>
            <b-col>
              <h5>{{ $t("common.arrival_until") }}</h5>
              {{ record.end_date | formatDate }}
            </b-col>
            <b-col>
              <h5>{{ $t("common.latest_reservation_date") }}</h5>
              {{ record.latest_reservation_date | formatDate }}
            </b-col>
          </b-row>
        </li>
        <li class="list-group-item">
          <b-row>
            <b-col>
              <h5>{{ $t("multiples.vehicle_class.one") }}</h5>
              {{ record.vehicle_class.name }}
            </b-col>
            <b-col>
              <h5>{{ $t("common.retention") }}</h5>
              {{ record.vehicle_class.retention | formatPrice }}{{ $t('unit.euro') }}
            </b-col>
            <b-col>
              <h5>{{ $t("common.deposit") }}</h5>
              {{ record.vehicle_class.deposit | formatPrice }}{{ $t('unit.euro') }}
            </b-col>
          </b-row>
        </li>
        <li class="list-group-item">
          <b-row>
            <b-col>
              <h5>{{ $t("common.distance") }}</h5>
              {{ record.distance | formatDistance }} {{ $t('unit.km') }}
            </b-col>
            <b-col>

              <h5>{{ $t("transfers.duration") }}</h5>
              {{ record.duration | formatDurationHours }} {{ $t('unit.hr') }}
              {{ record.duration | formatDurationMinutes }} {{ $t('unit.min') }}
            </b-col>
            <b-col>
              <h5>{{ $t("common.price") }}</h5>
              {{ record.price | formatPrice }}{{ $t('unit.euro') }}
            </b-col>
          </b-row>
        </li>
        <li class="list-group-item">
          <b-row>
            <b-col>
              <h5>{{ $t("common.mediation_via_ugoki") }}</h5>
              {{ record.mediation_via_ugoki | formatBoolean }}
            </b-col>
            <b-col>
              <h5>{{ $t("common.mediation_via_prologistics") }}</h5>
              {{ record.mediation_via_prologistics | formatBoolean }}
            </b-col>
            <b-col>
              <h5>{{ $t("common.mediation_via_trailer") }}</h5>
              {{ record.mediation_via_trailer | formatBoolean }}
            </b-col>
          </b-row>
        </li>
        <li v-if="record.hint" class="list-group-item">
          <h5>{{ $t("common.hint") }}</h5>
          <div v-html="$simpleFormat(record.hint)"></div>
        </li>
        <li v-if="record.notes" class="list-group-item">
          <h5>{{ $t("common.notes") }}</h5>
          {{ record.notes }}
        </li>
        <li class="list-group-item">
          <metadata-list v-model="record"/>
        </li>
      </ul>

      <template #modal-footer="{ cancel }">
        <div class="float-right">
          <b-button size="sm"
                    variant="default"
                    @click="cancel">
            {{ $t('button.back') }}
          </b-button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
import {getTransfer, putTransfer} from "@/services/transfers";
import MetadataList from "@/components/metadata_list";
import GenericInput from "@/components/generic_input";

export default {
  name: "TransferDetailModal",
  components: {GenericInput, MetadataList},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: -1,
    }
  },
  data() {
    return {
      visible: false,
      record: {}
    }
  },
  watch: {
    value: {
      immediate: true,
      async handler() {
        if (this.value && this.id > 0) this.record = await getTransfer(this.id) // TODO: catch
        this.visible = this.value
      }
    },
    visible: {
      immediate: true,
      handler() {
        this.$emit('input', this.visible)
      }
    }
  },
  computed: {},
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>