<template>
  <div class="dashboard">

    <div class="wrapper wrapper-content animated fadeInDown">
      <div class="row">
        <div class="col-lg-12">
          <div class="ibox">
            <div class="ibox-content no-borders b-r-lg">

              <!-- title -->
              <div class="row">
                <div class="col-md-4">
                  <div class="article">
                    <h1>{{ $t("multiples.transfer.other") }}</h1>
                  </div>
                </div>
              </div>

              <!-- table -->
              <div class="row">
                <div class="col-lg-12">
                  <div class="panel blank-panel">

                    <b-tabs content-class="mt-3" fill>
                      <b-tab :lazy="tab.recordsTotal < 0" v-for="tab in tabs" :title="$tabLabel(tab)">
                        <datatable :columns="tab.columns" :fetch-records="tab.fetchFunction" :actions="tab.actions"
                                   :registerWebsocket="tab.registerWebsocket" :filterQuery="tab.filterQuery">
                          <template slot="actions" v-if="tab.filterQuery.transfer_scope">
                            <searchable-select v-model="tab.filterQuery.transfer_scope" :fetchRecords="transferFilterOptions"/>
                          </template>
                        </datatable>
                      </b-tab>
                    </b-tabs>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <transfer-modal v-model="transferModalShow" :id="transferModalId" :title="transferModalTitle"
                    :type="transferModalType" @onCreateRequestResponse="handleTransferCreateResponse"/>
    <transfers-created-modal v-model="transfersCreatedModalShow" :records="transfersCreatedModalRecords" />
    <transfer-detail-modal v-model="transferDetailModalShow" :id="transferModalId"/>
    <reservation-modal v-model="reservationModalShow" :id="reservationModalId" :type="reservationModalType"/>
    <default-reservation-cancel-modal v-model="reservationCancelModalShow"/>
  </div>
</template>

<script>
import Datatable from "@/components/datatable";
import TransferModal from "@/components/rental/transfer_modal";
import TransferDetailModal from "@/components/rental/transfer_detail_modal";
import DefaultReservationCancelModal from "@/components/default_reservation_cancel_modal";
import ReservationModal from "@/components/rental/reservation_modal";
import {getTransfers, removeTransfer} from "@/services/transfers";
import {getReservations} from "@/services/reservations";
import SearchableSelect from "@/components/searchable_select.vue";
import TransfersCreatedModal from "@/components/rental/transfers_created_modal.vue";

export default {
  components: {
    TransfersCreatedModal,
    SearchableSelect,
    ReservationModal, DefaultReservationCancelModal, TransferDetailModal, TransferModal, Datatable},
  data() {
    return {
      transferModalId: 0,
      transferModalShow: false,
      transferModalType: '',
      transferModalTitle: '',
      transfersCreatedModalShow: false,
      transfersCreatedModalRecords: [],
      transferDetailModalShow: false,
      reservationModalId: 0,
      reservationModalShow: false,
      reservationModalType: '',
      reservationCancelModalShow: false,
      transferFilterOptions: [
        {id: "all", text: this.$t("selector.select_all_transfers")},
        {id: "where_no_transition", text: this.$t("selector.select_only_transfers")},
        {id: "where_has_transition", text: this.$t("selector.select_only_prepredicts")}
      ],
      tabs: [
        {
          id: 1,
          label: "transfers.available_transfers",
          columns: [
            {
              key: "reference_number",
              label: "id"
            },
            {
              key: "start_shop.city.name",
              label: "start",
              formatter: (value, key, item) => {
                return value + "<br>" + "<small>" + item?.start_shop?.street_name + ", " + item?.start_shop?.postcode + "</small>"
              }
            },
            {
              key: "end_shop.city.name",
              label: "end",
              formatter: (value, key, item) => {
                return value + "<br>" + "<small>" + item?.end_shop?.street_name + ", " + item?.end_shop?.postcode + "</small>"
              }
            },
            {
              key: "vehicle.name",
              label: "vehicle",
              formatter: (value, key, item) => {
                if (item.license_plate) return value + "<br>" + "<small>" + (item.license_plate || "") + "</small>"
                return value
              }
            },
            {key: "available_date", label: "available_until", formatter: this.$options.filters.formatDateDefault},
            {key: "notes", label: "notes"},
            {key: "actions", label: "actions"},
          ],
          filterQuery: {
            option: "open"
          },
          fetchFunction: async (filterQuery) => {
            let tab = this.tabs[0]
            const response = await getTransfers(filterQuery)
            tab.recordsTotal = response.recordsTotal || 0
            // this.$set(this.activeTabRecordsTotalCount, 1, response.recordsTotal || 0)
            response.data.forEach((entry) => {
              entry.actions = [
                {
                  label: this.$t('button.show'),
                  action: this.showTransferDetailModal
                },
                {
                  label: this.$t('button.edit'),
                  action: this.showEditTransferModal
                },
                {
                  label: this.$t('button.copy'),
                  action: this.showCopyTransferModal
                },
                {
                  label: this.$t('button.remove'),
                  action: (entry) => {
                    this.$makeRemoveDialog(this).then((r) => {
                      if (r) removeTransfer(entry.id)
                    })
                  }
                }
              ]
            })
            return response
          },
          actions: [
            {
              label: this.$t('transfers.add'),
              action: this.showCreateTransferModal
            },
          ],
          registerWebsocket: ['transfers'],
          recordsTotal: -1
        },
        {
          id: 2,
          label: "transfers.expired_transfers",
          columns: [
            {
              key: "reference_number",
              label: "id"
            },
            {
              key: "start_shop.city.name",
              label: "start",
              formatter: (value, key, item) => {
                return value + "<br>" + "<small>" + item?.start_shop?.street_name + ", " + item?.start_shop?.postcode + "</small>"
              }
            },
            {
              key: "end_shop.city.name",
              label: "end",
              formatter: (value, key, item) => {
                return value + "<br>" + "<small>" + item?.end_shop?.street_name + ", " + item?.end_shop?.postcode + "</small>"
              }
            },
            {
              key: "vehicle.name",
              label: "vehicle",
              formatter: (value, key, item) => {
                if (item.license_plate) return value + "<br>" + "<small>" + (item.license_plate || "") + "</small>"
                return value
              }
            },
            {
              key: "available_date",
              label: "available_until",
              formatter: this.$options.filters.formatDateDefault
            },
            {key: "state_description", label: "state"},
            {key: "notes", label: "notes"},
            {key: "actions", label: "actions"},
          ],
          filterQuery: {
            option: "removed"
          },
          fetchFunction: async (filterQuery) => {
            let tab = this.tabs[1]
            const response = await getTransfers(filterQuery)
            tab.recordsTotal = response.recordsTotal || 0
            response.data.forEach((entry) => {
              entry.actions = [
                {
                  label: this.$t('button.show'),
                  action: this.showTransferDetailModal
                },
                {
                  label: this.$t('button.restore'),
                  action: this.showRestoreTransferModal
                },
                {
                  label: this.$t('button.copy'),
                  action: this.showCopyTransferModal
                },
              ]
            })
            return response
          },
          actions: [
            {
              label: this.$t('transfers.add'),
              action: this.showCreateTransferModal
            },
          ],
          recordsTotal: -1
        },
        {
          id: 3,
          label: "transfers.reserved_transfers",
          columns: [
            {
              key: "reference_number",
              label: "id"
            },
            {
              key: "transfer.start_shop.city.name",
              label: "start",
              formatter: (value, key, item) => {
                return value + "<br>" + "<small>" + item?.transfer?.start_shop?.street_name + ", " + item?.transfer?.start_shop?.postcode + "</small>"
              }
            },
            {
              key: "transfer.end_shop.city.name",
              label: "end",
              formatter: (value, key, item) => {
                return value + "<br>" + "<small>" + item?.transfer?.end_shop?.street_name + ", " + item?.transfer?.end_shop?.postcode + "</small>"
              }
            },
            {
              key: "transfer.vehicle.name",
              label: "vehicle",
              formatter: (value, key, item) => {
                if (item.transfer.license_plate) return value + "<br>" + "<small>" + (item.transfer.license_plate || "") + "</small>"
                return value
              }
            },
            {key: "date_range", label: "pickup_time", formatter: this.$options.filters.formatDateRange},
            {
              key: "mover.source",
              label: "driver",
              formatter: (value, key, item) => {
                return value + ": " + item?.mover?.name
              }
            },
            {key: "notes", label: "notes"},
            {key: "actions", label: "actions"},
          ],
          filterQuery: {
            option: "open",
            transfer_scope: "all"
          },
          fetchFunction: async (filterQuery) => {
            let tab = this.tabs[2]
            const response = await getReservations(filterQuery)
            tab.recordsTotal = response.recordsTotal || 0
            response.data.forEach((entry) => {
              entry.actions = [
                {
                  label: this.$t('button.show'),
                  action: this.showReservationDetailModal
                },
                {
                  label: this.$t('button.edit'),
                  action: this.showEditReservationModal
                },
                {
                  label: this.$t('button.copy'),
                  action: this.showCopyTransferModal
                },
                {
                  label: this.$t('button.start_protocol'),
                  action: () => {
                    window.open(entry.start_protocol_url)
                  },
                  enabled: entry.start_protocol_url
                },
                {
                  label: this.$t('button.end_protocol'),
                  action: () => {
                    window.open(entry.end_protocol_url)
                  },
                  enabled: entry.end_protocol_url
                },
                {
                  label: this.$t('multiples.receipt.other'),
                  action: () => {
                    window.open(entry.receipts_url)
                  },
                  enabled: entry.receipts_url
                },
                {
                  label: this.$t('button.cancel'),
                  action: this.showReservationCancelModal,
                  enabled: entry.cancelable
                }
              ]
            })
            return response
          },
          actions: [
            {
              label: this.$t('transfers.add'),
              action: this.showCreateTransferModal
            }
          ],
          recordsTotal: -1
        },
        {
          id: 4,
          label: "transfers.closed_transfers",
          columns: [
            {
              key: "reference_number",
              label: "id"
            },
            {
              key: "transfer.start_shop.city.name",
              label: "start",
              formatter: (value, key, item) => {
                return value + "<br>" + "<small>" + item?.transfer?.start_shop?.street_name + ", " + item?.transfer?.start_shop?.postcode + "</small>"
              }
            },
            {
              key: "transfer.end_shop.city.name",
              label: "end",
              formatter: (value, key, item) => {
                return value + "<br>" + "<small>" + item?.transfer?.end_shop?.street_name + ", " + item?.transfer?.end_shop?.postcode + "</small>"
              }
            },
            {
              key: "transfer.vehicle.name",
              label: "vehicle",
              formatter: (value, key, item) => {
                if (item.transfer.license_plate) return value + "<br>" + "<small>" + (item.transfer.license_plate || "") + "</small>"
                return value
              }
            },
            {key: "date_range", label: "pickup_time", formatter: this.$options.filters.formatDateRange},
            {
              key: "mover.source",
              label: "driver",
              formatter: (value, key, item) => {
                return value + ": " + item?.mover?.name
              }
            },
            {
              key: "state_description",
              label: "state",
              formatter: (value, key, item) => {
                if (typeof item?.start_protocol_url === 'string' && typeof item?.end_protocol_url === 'string') return value + '<br>' + '+ ' + this.$t('common.recorded')
                return value
              }
            },
            {key: "notes", label: "notes"},
            {key: "actions", label: "actions"},
          ],
          filterQuery: {
            option: "removed_clustered",
            transfer_scope: "all"
          },
          fetchFunction: async (filterQuery) => {
            let tab = this.tabs[3]
            const response = await getReservations(filterQuery)
            tab.recordsTotal = response.recordsTotal || 0
            response.data.forEach((entry) => {
              entry.actions = [
                {
                  label: this.$t('button.show'),
                  action: this.showReservationDetailModal
                },
                {
                  label: this.$t('button.edit'),
                  action: this.showEditReservationModal
                },
                {
                  label: this.$t('button.copy'),
                  action: this.showCopyTransferModal
                },
                {
                  label: this.$t('button.start_protocol'),
                  action: () => {
                    window.open(entry.start_protocol_url)
                  },
                  enabled: entry.start_protocol_url
                },
                {
                  label: this.$t('button.end_protocol'),
                  action: () => {
                    window.open(entry.end_protocol_url)
                  },
                  enabled: entry.end_protocol_url
                },
                {
                  label: this.$t('multiples.receipt.other'),
                  action: () => {
                    window.open(entry.receipts_url)
                  },
                  enabled: entry.receipts_url
                },
                {
                  label: this.$t('button.cancel'),
                  action: this.showReservationCancelModal,
                  enabled: entry.cancelable
                }
              ]
            })
            return response
          },
          actions: [
            {
              label: this.$t('transfers.add'),
              action: this.showCreateTransferModal
            },
          ],
          recordsTotal: -1
        },
      ]
    }
  },
  methods: {
    showCreateTransferModal() {
      this.transferModalId = 0
      this.transferModalType = 'create'
      this.transferModalTitle = this.$t('transfers.add')
      this.transferModalShow = true
    },
    showEditTransferModal(entry) {
      this.transferModalId = entry.id
      this.transferModalType = 'edit'
      this.transferModalTitle = this.$t('transfers.edit')
      this.transferModalShow = true
    },
    showRestoreTransferModal(entry) {
      this.transferModalId = entry.id
      this.transferModalType = 'restore'
      this.transferModalTitle = this.$t('transfers.restore')
      this.transferModalShow = true
    },
    showCopyTransferModal(entry) {
      this.transferModalId = entry.transfer_id || entry.id
      this.transferModalType = 'create'
      this.transferModalTitle = this.$t('transfers.add')
      this.transferModalShow = true
    },
    showTransferDetailModal(entry) {
      this.transferModalId = entry.id
      this.transferDetailModalShow = true
    },
    showEditReservationModal(entry) {
      this.reservationModalId = entry.id
      this.reservationModalType = 'edit'
      this.reservationModalShow = true
    },
    showReservationDetailModal(entry) {
      this.reservationModalId = entry.id
      this.reservationModalType = 'show'
      this.reservationModalShow = true
    },
    showReservationCancelModal() {
      this.reservationCancelModalShow = true
    },
    handleTransferCreateResponse(promise) {
      promise.then((response)=>{
        this.transfersCreatedModalRecords = response
        this.transfersCreatedModalShow = true
      })
    }
  }
}
</script>